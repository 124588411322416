<template>
<div class="poi-container">
    <div style="width: 100%; display: flex; justify-content: space-between; align-items: center; gap: 10px;">
        <vs-row direction="row" justify="flex-start" align="center">
            <img v-if="poi != null" :src="baseUri + '/targets/' + poi.target_id + '/picture'" style="width: auto; height: 64px;" />

            <div style="display: flex; justify-content: center; flex-direction: column; gap: 5px;">
                <h3 style="margin: 0;">{{ poi != null ? poi.name : 'Loading..' }}</h3>
                <div style="display: flex; justify-content: flex-start; align-items: center; gap: 10px;">
                    <Stars :stars="poi != null ? Math.floor(poi.average_rating) : 0" />
                    <p style="margin: 0;" class="light">{{ poi != null ? poi.number_of_reviews : 0 }} {{ $t('common.reviews') }}</p>
                </div>
            </div>
        </vs-row>

        <vs-tooltip v-model="upgradeTooltip" not-arrow not-hover :bottom="isMobile" :left="!isMobile" border color="#EF7E18" style="padding: 0; margin: 0;" v-if="user != null">
            <vs-button v-if="poi != null && poi.my_place" @click="togglePoiMyPlaces" dark color="#FFE6D0" style="width: 50px;"><img src="../../assets/icons/pin.png" style="width:24px;" /></vs-button>
            <vs-button v-else @click="togglePoiMyPlaces" dark style="width: 50px;"><img src="../../assets/icons/pin_grey.png" style="width:24px;" /></vs-button>

            <template #tooltip>
                <div class="content-tooltip">
                    <h4 class="center">
                        {{ $t('common.thisFeatureIsOnlyAvailableInPremiumVersion') }}
                    </h4>
                    <footer>
                        <vs-button block size="large" @click="upgrade" :loading="upgradeLoading">
                            {{ $t('profile.subscription.upgrade') }}
                        </vs-button>
                    </footer>
                </div>
            </template>
        </vs-tooltip>
    </div>

    <div class="poi-section">
        <p class="bold">{{ $t('common.target') }}</p>
        <p>{{ poi != null ? poi.target_name : 'Loading..' }}</p>
    </div>

    <div class="poi-section">
        <p class="bold">{{ $t('poi.yearOfConstruction') }}</p>
        <p>{{ poi != null ? poi.year_built : '----' }}</p>
    </div>

    <div class="poi-section">
        <p class="bold">{{ $t('poi.author') }}</p>
        <p>{{ poi != null ? (poi.author == '' ? '-' : poi.author) : 'Loading..' }}</p>
    </div>

    <div class="poi-section">
        <p class="bold">{{ $t('poi.description') }}</p>
        <p>{{ poi != null ? (fullDescription ? poi.description : poi.description.substr(0,200)) : 'Loading..'}}</p>
        <a style="cursor: pointer;" @click="fullDescription = true" v-if="!fullDescription && poi != null && poi.description.length > 200">{{ $t('common.showMore') }}</a>
        <a style="cursor: pointer;" @click="fullDescription = false" v-if="fullDescription">{{ $t('common.showLess') }}</a>
    </div>

    <div style="display: flex; justify-content: space-between; width: 100%; gap: 15px;">
        <vs-button dark block class="bold" @click="addToTrip"><img src="../../assets/icons/trip.png" style="width: 24px;" @click="addToTrip" />{{ $t('poi.addToTrip').toUpperCase() }}</vs-button>
        <vs-button v-if="poi != null && poi.liked" @click="togglePoiLike" dark color="#FFE6D0" style="width: 50px;"><img src="../../assets/icons/favourite.png" style="width:24px;" /></vs-button>
        <vs-button v-else @click="togglePoiLike" dark style="width: 50px;"><img src="../../assets/icons/favourite_grey.png" style="width:24px;" /></vs-button>
    </div>

    <div style="display: flex; justify-content: flex-start; width: 100%; align-items: center; gap: 5px;">
        <img src="../../assets/icons/location.png" style="width: 24px; height: 24px;" />
        <p class="bold">{{ poi != null ? poi.street : 'Loading..' }}</p>
    </div>

    <div class="map-gradient">
        <div class="gradient"></div>
        <OpenStreetMap style="width: 100%; height: 120px; " v-if="poi != null" :controls-enabled="false" :center="[poi.latitude, poi.longitude]" :pois="[poi]" />
    </div>

    <div style="display: flex; justify-content: space-between; width: 100%; gap: 15px;">
        <vs-button dark block class="bold" @click="getDirections"><img src="../../assets/icons/direction.png" style="width: 18px;" />{{ $t('poi.getDirections').toUpperCase() }}</vs-button>
        <vs-button dark style="width: 50px;" :alt="poi != null ? poi.website : ''" @click="openWebsite" :disabled="poi == null || poi.website == ''"><img src="../../assets/icons/www.png" style="width: 30px;" /></vs-button>
    </div>

    <div style="display: flex; justify-content: space-between; align-items: center; width: 100%;">
        <p class="bold">{{ $t('common.reviews') }}</p>
        <p class="bold" style="display: flex; align-items: center; gap: 5px; cursor: pointer;" @click="$router.push({name: 'Recensioni'})">{{ $t('common.showAll') }} <img src="../../assets/icons/right.png" style="width: 16px;" /></p>
    </div>

    <Reviews :poi="poi" :user="user" />

</div>
</template>

<script>
import {
    apiCall,
    baseUri
} from '../../utils/ApiMiddleware';
import Stars from '../Stars.vue'
import OpenStreetMap from '../OpenStreetMap.vue';
import Reviews from '../Reviews.vue';
import AddToTripDialog from '../dialogs/AddToTripDialog.vue';
import TripDateDialog from '../dialogs/TripDateDialog.vue';
import NewTripDialog from '../dialogs/NewTripDialog.vue';
import {
    GlobalEventEmitter
} from '@/utils/GlobalEventEmitter'

export default {
    name: "poi",
    props: {
        user: Object,

        isMobile: {
            type: Boolean,
            default: false
        }
    },
    components: {
        Stars,
        OpenStreetMap,
        Reviews,
    },

    data() {
        return {
            poi: null,
            fullDescription: false,
            selectedTrip: null,
            baseUri,
            upgradeTooltip: false,
            upgradeLoading: false,
        }
    },

    async mounted() {
        await this.getPoi(this.$route.params.id)
    },

    methods: {

        async togglePoiLike() {
            if (this.poi == null) {
                return;
            }
            this.poi.liked = !this.poi.liked;

            // call the api to toggle the like
            const response = await apiCall('PUT', '/pois/' + this.poi.id + '/like', {
                liked: this.poi.liked
            })
            if (response.status == 200) {
                // success
                this.$vs.notification({
                    title: this.$t('common.messages.success'),
                    text: this.$t('common.poi') + ' ' + (this.poi.liked ? this.$t('common.addedTo').toLowerCase() : this.$t('common.removedFrom').toLowerCase()) + ' ' + this.$t('common.favourites').toLowerCase(),
                    color: 'success',
                    position: 'top-right'
                })
            } else if (response.status != 0) {
                // show an error message
                this.$vs.notification({
                    title: this.$t('common.messages.somethingWentWrong'),
                    text: this.$t('poi.messages.cannotAddToFavourites'),
                    color: 'danger',
                    position: 'top-right'
                })
                this.poi.liked = !this.poi.liked;
            }
        },

        upgrade() {
            this.upgradeLoading = true;
            setTimeout(() => {
                this.upgradeLoading = false;
                this.upgradeTooltip = false;
                this.$router.push({name: 'Sottoscrizione'})
            }, 1000);
        },

        async togglePoiMyPlaces() {
            if (this.poi == null) {
                return;
            }

            if(this.user.subscription == null && !this.user.is_partner && !this.user.is_admin){
                this.upgradeTooltip = true;
                return;
            }

            this.poi.my_place = !this.poi.my_place;

            // call the api to toggle the like
            const response = await apiCall('PUT', '/pois/' + this.poi.id + '/myplaces', {
                added: this.poi.my_place
            })
            if (response.status == 200) {
                // success
                this.$vs.notification({
                    title: this.$t('common.messages.success'),
                    text: this.$t('common.poi') + ' ' + (this.poi.my_place ? this.$t('common.addedTo').toLowerCase() : this.$t('common.removedFrom').toLowerCase()) + ' ' + this.$t('explore.myPlaces').toLowerCase(),
                    color: 'success',
                    position: 'top-right'
                })
            } else if (response.status != 0) {
                // show an error message
                this.$vs.notification({
                    title: this.$t('common.messages.somethingWentWrong'),
                    text: this.$t('poi.messages.cannotAddToMyPlaces'),
                    color: 'danger',
                    position: 'top-right'
                })
                this.poi.my_place = !this.poi.my_place;
            }
        },

        openWebsite() {
            // open the url in new window
            window.open(this.poi.website, '_blank')
        },

        getDirections() {
            window.open('https://www.google.com/maps/search/?api=1&query=' + this.poi.latitude + ',' + this.poi.longitude);
        },

        async getPoi(id) {
            const loading = this.$vs.loading();
            const response = await apiCall('GET', '/pois/' + id, {
                include_like_details: true,
                include_my_places_details: true
            });
            if (response.status == 200) {
                this.poi = response.data;
                GlobalEventEmitter.$emit('setZoom', 15)
                GlobalEventEmitter.$emit('goToCoords', [this.poi.latitude, this.poi.longitude])
            } else if (response.status != 0) {
                this.$vs.notification({
                    title: this.$t('common.messages.somethingWentWrong'),
                    text: this.$t('poi.messages.cannotLoadPoi'),
                    color: 'danger',
                    position: 'top-right'
                })
            }
            loading.close();
        },

        addToTrip() {
            GlobalEventEmitter.$emit('showDialog', AddToTripDialog, this.addToTripCallback)
        },

        async addToTripCallback(e) {
            if (e === null) {
                // creare nuovo trip
                GlobalEventEmitter.$emit('showDialog', NewTripDialog, this.newTripCallback) // chiedo il nome
            } else if (e !== false && e.id != null) {
                var tripDetails = await this.getTrip(e.id)
                if (tripDetails != null) {
                    this.selectedTrip = tripDetails;
                    GlobalEventEmitter.$emit('showDialog', TripDateDialog, this.addToTripDateChosenCallback, tripDetails) // chiedo la data in cui intende visitare il poi

                }
            }

        },

        async addToTripDateChosenCallback(e) {
            console.log(e)
            var vm = this;
            if (e != null && e.length > 0) { // ha scelto un nome per il viaggio e ha premuto "prosegui" (non annulla)
                // to do, at the moment doesn't do anything
                if (this.selectedTrip.pois == null) {
                    this.selectedTrip.pois = []
                }
                this.selectedTrip.pois.push({
                    id: this.poi.id,
                    is_custom_poi: false,
                    start_date: e + 'T00:00:00Z',
                    end_date: e + 'T00:00:00Z',
                    position: this.selectedTrip.pois.length // to be sure it's the last one

                })
                console.log(this.selectedTrip)
                const loading = this.$vs.loading();
                if (await this.saveTrip()) {
                    this.$router.push({
                        path: '/explore/trips/' + vm.selectedTrip.id
                    });
                }
                loading.close();

            }
        },

        async newTripCallback(e) {

            if (e != null && e.length > 0) { // ha scelto un nome per il viaggio e ha premuto "prosegui" (non annulla)
                if (await this.newTrip(e)) {
                    GlobalEventEmitter.$emit('showDialog', TripDateDialog, this.addToTripDateChosenCallback) // chiedo la data in cui intende visitare il poi
                }

            }
        },

        async getTrip(id) {

            // use apiCall to make a request to /trips
            const response = await apiCall('GET', '/journey/' + id);
            // if status code is 200, show a success message 
            if (response.status == 200) {
                return response.data;
            } else if (response.status != 0) {
                this.$vs.notification({
                    title: this.$t('common.messages.somethingWentWrong'),
                    text: this.$t('trips.messages.unableToLoadTrip'),
                    color: 'danger',
                    position: 'top-right'
                });

            }
            return null;
        },

        async saveTrip() {

            const response = await apiCall('PUT', '/journeys/' + this.selectedTrip.id, this.selectedTrip);
            // if status code is 200, show a success message 
            if (response.status == 200) {
                // show sucecss message
                this.$vs.notification({
                    title: this.$t('common.messages.success'),
                    text: this.$t('trips.messages.tripUpdated'),
                    color: 'success',
                    position: 'top-right'
                });
                return true;
            } else if (response.status == 409) {
                // show sucecss message
                this.$vs.notification({
                    title: this.$t('common.messages.somethingWentWrong'),
                    text: this.$t('trips.messages.poiAlreadyInTrip'),
                    color: 'danger',
                    position: 'top-right'
                });
            } else if (response.status != 0) {
                this.$vs.notification({
                    title: this.$t('common.messages.somethingWentWrong'),
                    text: this.$t('poi.messages.cannotAddToTrip'),
                    color: 'danger',
                    position: 'top-right'
                });
            }
            return false;
        },

        async newTrip(name) {
            // use apiCall to make a request to /trips
            const response = await apiCall('POST', '/journeys', {
                name
            });
            // if status code is 200, show a success message 
            if (response.status == 200) {
                // show a success message
                this.$vs.notification({
                    title: this.$t('common.messages.success'),
                    text: this.$t('trips.messages.tripCreated'),
                    color: 'success',
                    position: 'top-right'
                });
                this.selectedTrip = {
                    id: response.data.id,
                    name
                }

                return true;

            } else if (response.status != 0) {
                this.$vs.notification({
                    title: this.$t('common.messages.somethingWentWrong'),
                    text: this.$t('trips.messages.cannotCreateTrip'),
                    color: 'danger',
                    position: 'top-right'
                });

            }
            return false;

        },

    }
}
</script>

<style scoped>
.poi-container {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
    padding: 0;
    margin: 0;
    gap: 15px;
    width: 100%;
    padding-bottom: 25px;
}

.map-gradient {
    height: 120px;
    width: 100%;
    position: relative;
}

.map-gradient div {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
}

.map-gradient .gradient {

    z-index: 2000;
    background: linear-gradient(180deg, rgba(255, 255, 255, 1) 1%, rgba(255, 255, 255, 0.0001) 25%, rgba(255, 255, 255, 1) 100%);
}

p {
    margin: 0;
}

.poi-section {
    display: flex;
    flex-direction: column;
    gap: 5px;
    width: 100%;
}

.vs-button {
    margin: 0 !important;
}

/* MOBILE */
@media (max-width: 600px) {
    .poi-container {
        padding-left: 20px;
        padding-right: 20px;
        box-sizing: border-box;
        padding-bottom: 90px !important;
        width: 100% !important;

    }

}
.content-tooltip {
    padding: 10px;
    box-sizing: border-box;
}

.content-tooltip button {
    margin: 0;
    margin-bottom: 5px;
}
</style>
